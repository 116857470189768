// Generated by Framer (abcfa95)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const enabledGestures = {RTjWOm_Fl: {hover: true}};

const cycleOrder = ["RTjWOm_Fl"];

const serializationHash = "framer-K993t"

const variantClassNames = {RTjWOm_Fl: "framer-v-rz1mn7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, text, width, ...props}) => { return {...props, Cc7o78ELw: text ?? props.Cc7o78ELw ?? "Home", SafkKsQzH: link ?? props.SafkKsQzH} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string;text?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, SafkKsQzH, Cc7o78ELw, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "RTjWOm_Fl", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={SafkKsQzH} smoothScroll><motion.a {...restProps} {...gestureHandlers} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-rz1mn7", className, classNames)} framer-145o0a3`} data-border data-framer-name={"Footer Button"} layoutDependency={layoutDependency} layoutId={"RTjWOm_Fl"} ref={ref ?? ref1} style={{"--border-bottom-width": "1px", "--border-color": "rgba(86, 86, 86, 0)", "--border-left-width": "1px", "--border-right-width": "1px", "--border-style": "solid", "--border-top-width": "1px", backgroundColor: "rgba(76, 76, 76, 0)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, ...style}} variants={{"RTjWOm_Fl-hover": {"--border-color": "rgb(86, 86, 86)", backgroundColor: "rgb(76, 76, 76)"}}} {...addPropertyOverrides({"RTjWOm_Fl-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "SW50ZXItTWVkaXVt", "--framer-font-family": "\"Inter\", \"Inter Placeholder\", sans-serif", "--framer-font-size": "17px", "--framer-font-weight": "500", "--framer-line-height": "150%", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-f9e45599-fd40-48ec-aab5-41b4b1e1cd80, rgb(255, 255, 255)))"}}>Home</motion.p></React.Fragment>} className={"framer-151g5q2"} data-framer-name={"Text"} fonts={["Inter-Medium"]} layoutDependency={layoutDependency} layoutId={"i2ksP64Kf"} style={{"--extracted-r6o4lv": "var(--token-f9e45599-fd40-48ec-aab5-41b4b1e1cd80, rgb(255, 255, 255))", "--framer-paragraph-spacing": "0px"}} text={Cc7o78ELw} verticalAlignment={"top"} withExternalLayout/></motion.a></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-K993t.framer-145o0a3, .framer-K993t .framer-145o0a3 { display: block; }", ".framer-K993t.framer-rz1mn7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 12px 16px 12px 16px; position: relative; text-decoration: none; width: min-content; will-change: var(--framer-will-change-override, transform); }", ".framer-K993t .framer-151g5q2 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-K993t.framer-rz1mn7 { gap: 0px; } .framer-K993t.framer-rz1mn7 > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-K993t.framer-rz1mn7 > :first-child { margin-left: 0px; } .framer-K993t.framer-rz1mn7 > :last-child { margin-right: 0px; } }", ".framer-K993t[data-border=\"true\"]::after, .framer-K993t [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 50
 * @framerIntrinsicWidth 80
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"rXmR48TMK":{"layout":["auto","auto"]}}}
 * @framerVariables {"SafkKsQzH":"link","Cc7o78ELw":"text"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FrameruTKtEIoiU: React.ComponentType<Props> = withCSS(Component, css, "framer-K993t") as typeof Component;
export default FrameruTKtEIoiU;

FrameruTKtEIoiU.displayName = "Buttons/ Footer Button";

FrameruTKtEIoiU.defaultProps = {height: 50, width: 80};

addPropertyControls(FrameruTKtEIoiU, {SafkKsQzH: {title: "Link", type: ControlType.Link}, Cc7o78ELw: {defaultValue: "Home", displayTextArea: false, title: "Text", type: ControlType.String}} as any)

addFonts(FrameruTKtEIoiU, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5A3Ce6C9YYmCjpQx9M4inSaKU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/Qx95Xyt0Ka3SGhinnbXIGpEIyP4.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/6mJuEAguuIuMog10gGvH5d3cl8.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/xYYWaj7wCU5zSQH0eXvSaS19wo.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/otTaNuNpVK4RbdlT7zDDdKvQBA.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/d3tHnaQIAeqiE5hGcRw4mmgWYU.woff2", weight: "500"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/DolVirEGb34pEXEp8t8FQBSK4.woff2", weight: "500"}]}], {supportsExplicitInterCodegen: true})